

























import { Component, Mixins } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { Inject, IRelatedService, Loading, RelatedServiceType } from '../../../front'
import YouTubeModal from '../../partials/YouTubeModal/YoutubeModal.vue'

import { AbstractModuleUi } from '../../abstract/ui'
import { VideoTeaserModule } from '../VideoTeaser.contracts'
import { YouTubeUi } from './versions/youtube'

/**
 * Component capable of rendering the `EmbedModule`'s contents.
 *
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<VideoModuleUi>({
  name: 'VideoModuleUi',
  components: { YouTubeUi, YouTubeModal },
  mounted (): void {
    this.markAsReady()
  }
})
export class VideoModuleUi extends Mixins<AbstractModuleUi<VideoTeaserModule>, Loading>(
  AbstractModuleUi,
  Loading
) {
  @Inject(EventbusType)
  public eventBus!: IEventbus

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public get carouselConfig () {
    return {
      hasPagination: true,
      inLoop: false,
      draggable: true,
      hasButtons: false,
      variableWidth: true
    }
  }

  public getIdentifier (videoId: string, index: string) {
    return `${videoId}-${index}`
  }
}

export default VideoModuleUi
