


































import { Component, Prop, Vue } from 'vue-property-decorator'
import { IEventbus } from '@movecloser/front-core'

import { ImageProps } from '../../../../../dsl/atoms/Image'

import { toImageProps } from '../../../../../front/shared/support/'

import { IRelatedService } from '../../../../../contexts'

import {
  VideoTeaserModuleContent
} from '../../../VideoTeaser.contracts'

import { UiMarkdown } from '../../../../partials/UiMarkdown'

/**
 * Component capable of rendering the `EmbedModule`
 * with the version set to `EmbedVersion.YouTube`.
 *
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<YouTubeUi>({
  name: 'YouTubeUi',
  components: { UiMarkdown },
})

export class YouTubeUi extends Vue {
  @Prop({ type: String, required: true })
  public readonly videoIdentifier!: string

  @Prop({ type: String, required: false })
  public readonly title?: VideoTeaserModuleContent['title']

  @Prop({ type: String, required: false })
  public readonly description?: VideoTeaserModuleContent['description']

  @Prop({ type: Object, required: true })
  public readonly eventBus?: IEventbus

  @Prop({ type: Object, required: false })
  public readonly thumbnail?: VideoTeaserModuleContent['thumbnail']

  @Prop({ type: String, required: true })
  public readonly videoId!: VideoTeaserModuleContent['videoId']

  public get image (): ImageProps {
    if (!this.thumbnail) {
      return this.youTubeCover
    }

    return toImageProps(this.thumbnail)
  }

  public get youTubeCover (): ImageProps {
    return {
      src: `https://img.youtube.com/vi/${this.videoId}/0.jpg`,
      alt: 'YouTube cover video'
    }
  }

  public get hasVideoId (): boolean {
    return typeof this.videoId === 'string' && this.videoId.length > 0
  }

  public onPlayBtnClick (): void {
    this.eventBus?.emit('play', { videoIdentifier: this.videoIdentifier })
  }
}

export default YouTubeUi
